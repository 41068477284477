@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

@import './base.scss';

body.layout-v4 {
  margin: 0;
  padding: 0;
  background-color: #202020;
  color: #fff;
  font-size: 16px;
  font-family: Inter;

  * {
    font-family: Inter;
  }
}

button:hover {
  cursor: pointer;
}

@media (min-width: 992px) {
  .mobile-only {
    display: none !important;
  }

  .tablet-only {
    display: none !important;
  }
}

@media (min-width: 479px) and (max-width: 991px) {
  .desktop-only {
    display: none !important;
  }

  .mobile-only {
    display: none !important;
  }
}

@media (max-width: 478px) {
  .desktop-only {
    display: none !important;
  }

  .tablet-only {
    display: none !important;
  }

  .desktop-tablet-only {
    display: none !important;
  }
}

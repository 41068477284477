.Layout {
  display: flex;
  flex-direction: column;

  --banner-height: 60px;

  @media (max-width: 478px) {
    --banner-height: 67px;
  }

  .Banner {
    display: flex;
    color: #fff;
    background-color: var(--blue);
    justify-content: center;
    align-items: center;
    align-self: stretch;
    position: sticky;
    top: 0;
    z-index: 2;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      text-align: center;
      height: var(--banner-height);
      font-weight: 400;
      line-height: 26px;
      letter-spacing: -0.54px;

      @media (max-width: 478px) {
        height: var(--banner-height);
        padding: 0 40px;
      }

      a {
        color: #fff;
      }
    }
  }

  .Header {
    display: flex;
    position: sticky;
    top: var(--banner-height);
    padding: 8px 80px;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    background: rgba(32, 32, 32, 0.14);
    z-index: 1;
    width: 100%;
    height: 92px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 991px) {
      padding: 8px 30px;
    }

    @media (max-width: 478px) {
      padding: 20px 29px;
      justify-content: space-between;
      height: 73px;
    }

    .Logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 220px;
      height: 34px;

      @media (max-width: 478px) {
        zoom: 80%;
      }
    }

    .MenuItems {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 50px;
      flex-grow: 1;
      margin-left: 81px;
      margin-right: 50px;

      > li {
        > a {
          color: #fff;
          font-size: 1.2em;
          letter-spacing: 2px;
          text-transform: uppercase;
          white-space: nowrap;
          position: relative;

          &.SelectedLink {
            &:after {
              content: '';
              position: absolute;
              bottom: -6px;
              left: 0;
              width: 100%;
              height: 4px;
              background: var(--blue);
            }
          }
        }
      }
    }

    .SecondaryMenuItems {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px;
      margin-right: 40px;

      @media (max-width: 478px) {
        display: none;
      }

      > li {
        > a {
          color: #fff;
          font-size: 1.2em;
          white-space: nowrap;
        }
      }
    }

    span {
      @media (max-width: 478px) {
        zoom: 80%;
      }
    }

    .Actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 17px;
      white-space: nowrap;

      button {
        color: #fff;
        padding: 11px 18px;
        font-size: 0.93em;
        line-height: 113%;
        white-space: nowrap;
        cursor: pointer;
        border-radius: 4px;

        &.btn-signin {
          border: 1px solid #fff;
        }

        &.btn-signup {
          border: 1px solid var(--blue);
          background-color: var(--blue);
        }

        &.btn-signout {
          border: 1px solid #fff;
        }
      }
    }
  }

  .ContentContainer {
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    width: 900px;
    padding: 0;

    @media (max-width: 991px) {
      width: 100%;
      max-width: 700px;
      padding: 0;
    }

    @media (max-width: 478px) {
      width: 100%;
      padding: 0 28px;
    }
  }

  .Footer {
    margin-top: 30px;
    margin-bottom: 75px;
    max-width: 1440px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    > div {
      display: flex;
      flex-direction: column;
      padding: 0 80px;
      margin-left: auto;
      margin-right: auto;

      @media (max-width: 478px) {
        padding: 0 30px;
      }

      .FooterLine {
        width: 100%;
        height: 1px;
        border-top: 1px solid var(--lesserlightgray);
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .FooterGrid {
        display: grid;
        grid-template-columns: 100px 1fr 420px;
        margin-top: 10px;

        @media (min-width: 992px) {
          grid-auto-columns: 100px 1fr 420px;
        }
        @media (max-width: 991px) {
          grid-template-columns: 100px 1fr;
          grid-auto-rows: unset;
        }

        @media (max-width: 478px) {
          grid-template-columns: unset;
          grid-auto-flow: row;
          grid-auto-rows: auto;
          grid-auto-columns: unset;
        }

        .FooterLogo {
          grid-column: 1;
          margin-bottom: 30px;

          @media (min-width: 479px) and (max-width: 991px) {
            grid-row: span 5;
          }
        }

        .FooterCompany {
          padding-right: 50px;
          grid-row: span 2;

          @media (max-width: 991px) {
            margin-bottom: 60px;
            padding-right: 0;
          }

          @media (max-width: 478px) {
            padding-right: 0;
          }

          > div {
            display: flex;
            flex-direction: column;

            > p {
              flex-grow: 1;
              color: var(--lightgray);
              font-family: 'Times New Roman';
              font-size: 1.375em;
              line-height: 140%;

              @media (max-width: 478px) {
                display: none;
              }
            }
          }

          .FooterMenuItems {
            display: flex;
            gap: 8px 50px;
            flex-wrap: wrap;
            margin-bottom: 40px;
            align-items: center;
            height: 57px;

            @media (max-width: 478px) {
              gap: 11px 47px;
            }

            > li {
              > a {
                color: var(--lightgray);
                font-size: 0.97em;
                letter-spacing: 0.779px;
              }
            }
          }
        }

        .FooterNewsletter {
          display: flex;
          flex-direction: column;
          gap: 19px;
          grid-row: span 2;

          @media (max-width: 478px) {
            margin-bottom: 30px;
          }

          > h3 {
            color: var(--lightgray);
            font-size: 0.97em;
            letter-spacing: 0.779px;
            text-transform: uppercase;
          }

          > p {
            color: var(--lightgray);
            font-size: 0.97em;
            letter-spacing: 0.779px;
            text-transform: uppercase;
          }

          > form {
            display: flex;
            flex-wrap: wrap;

            > input {
              flex-grow: 1;
              padding: 11px 18px;
              border-radius: 4px;
              border: 1px solid #fff;
              color: #fff;
            }

            > button {
              padding: 11px 21px 11px 18px;
              border-radius: 4px;
              border: 1px solid var(--blue);
              background-color: var(--blue);
              color: #fff;
              white-space: nowrap;
            }
          }
        }

        .FooterSNS {
          display: flex;
          flex-direction: row;
          margin-top: 40px;
          zoom: 90%;
          gap: 20px;
          cursor: pointer;

          @media (min-width: 479px) and (max-width: 991px) {
            margin-top: 40px;
            gap: 16px;
          }

          @media (min-width: 992px) {
            grid-column: 1;
            align-self: end;
            gap: 16px;
          }
        }
      }

      .FooterCopyright {
        color: var(--darkergray);
        font-size: 0.85em;

        @media (max-width: 478px) {
          margin-top: 10px;
        }
      }
    }
  }
}

.MobileMenu {
  position: fixed;
  top: 67px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--darkgray);
  color: #fff;
  z-index: 1;

  .Body {
    padding: 30px;
  }

  .MenuItems {
    border-top: 1px solid var(--gray);
    padding-top: 45px;
    padding-bottom: 30px;

    > li {
      margin-top: 20px;
      margin-bottom: 45px;

      > a {
        color: #fff;
        font-size: 1.21em;
        white-space: nowrap;
        position: relative;

        &.SelectedLink {
          &:after {
            content: '';
            position: absolute;
            bottom: -6px;
            left: 0;
            width: 100%;
            height: 4px;
            background: var(--blue);
          }
        }
      }
    }
  }

  .FooterMenuItems {
    display: flex;
    gap: 11px 47px;
    flex-wrap: wrap;
    margin-bottom: 40px;
    align-items: center;
    height: 57px;
    margin-top: 120px;

    > li {
      > a {
        color: var(--lightgray);
        font-size: 1em;
      }
    }
  }
}

.ClearPage {
  margin-bottom: 80px;
  font-weight: 400;
  font-family: Inter;
  width: 100%;

  .ProductBg {
    position: absolute;
    height: 768px;
    width: 100%;
    z-index: -1;
    top: var(--banner-height);
    left: 0;
    background-image: url(../../common/assets/images/v4/product/clear/Clear_KeyImg.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    zoom: 90%;

    @media (min-width: 479px) {
      height: 899px;
      zoom: 1;
    }
  }

  .Title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 65.17px;

    .AiPoweredBySupertone {
      width: 142px;
      height: 54.897px;
      margin-top: 0;
      zoom: 90%;

      @media (min-width: 992px) {
        width: 194px;
        height: 75px;
      }
    }

    .ProductTitle {
      margin-top: 40px;
      zoom: 58%;

      @media (min-width: 479px) and (max-width: 991px) {
        margin-top: 54.91px;
        zoom: 65%;
      }

      @media (min-width: 992px) {
        zoom: 1;
      }

      div + div {
        margin-top: 4px;
      }

      .Big {
        svg + svg {
          margin-left: 11px;
        }
      }

      .Small {
        > div {
          margin-left: 2px;
          font-size: 16.8.748px;
          line-height: 28.728px;
          letter-spacing: -0.622px;

          @media (min-width: 479px) {
            font-size: 26px;
            line-height: 36px;
            letter-spacing: -0.78px;
          }
        }
      }
    }

    .ProductSubtitle {
      zoom: 85%;

      > div {
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -1.08px;

        @media (min-width: 992px) {
          font-size: 50px;
          line-height: 60px;
          letter-spacing: -0.08px;
        }

        @media (min-width: 479px) and (max-width: 991px) {
          font-size: 48px;
          line-height: 54px;
          letter-spacing: -1.44px;
        }
      }
    }

    .ProductDescription {
      font-size: 16.8px;
      line-height: 36px;
      letter-spacing: -0.72px;
      margin-top: -25px;

      @media (min-width: 992px) {
        font-size: 30.24px;
        line-height: 44px;
        letter-spacing: -1.08px;
      }
    }
  }

  .ClearOverview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    text-align: center;

    .ProductInfo {
      position: relative;

      img {
        margin-top: 67px;
        @media (max-width: 478px) {
          zoom: 90%;
          width: 341px;
          height: 227px;
        }

        @media (min-width: 479px) and (max-width: 991px) {
          width: 523px;
          height: 347px;
          margin-top: 31px;
        }

        @media (min-width: 992px) {
          width: 751px;
          height: 499px;
        }
      }
    }

    .ProductDescription {
      display: flex;
      flex-direction: column;
      align-items: center;

      .DescriptionTitle {
        font-size: 16.8px;
        line-height: 30px;
        letter-spacing: -0.6px;
        color: var(--lightergray);

        @media (min-width: 479px) {
          font-size: 30px;
          line-height: 42px;
          letter-spacing: -0.9px;
        }
      }

      .Price {
        color: #fff;
        font-size: 40.32px;
        line-height: 54px;
        letter-spacing: -1.44px;

        @media (min-width: 479px) {
          font-size: 64px;
          line-height: 60px;
          letter-spacing: -1.92px;
          margin-top: 15px;
        }
      }

      .ProductPriceDesc {
        font-size: 12px;
        line-height: 20px;
        letter-spacing: -0.36px;
        color: var(--lightergray);

        @media (min-width: 479px) {
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.42px;
          margin-top: 15px;
        }
      }
    }

    .ProductPrice > div {
      font-size: 28px;
      line-height: 40px;
      letter-spacing: -0.84px;

      @media (min-width: 479px) {
        font-size: 30px;
        line-height: 42px;
        letter-spacing: -0.9px;
      }
    }

    .DownloadButtons {
      display: flex;
      gap: 26px;

      @media (max-width: 478px) {
        flex-direction: column;
      }

      button {
        display: flex;
        width: 190px;
        height: 58px;
        min-width: 190px;
        padding: 19px 20px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        color: #fff;
        border-radius: 4.87px;
        background-color: var(--blue);

        font-size: 16.8px;
        line-height: 30px;
        letter-spacing: -0.6px;

        svg {
          margin-left: 15px;
        }
      }
    }
  }

  .ProductTitle {
    div + div {
      margin-top: 12px;
    }

    .Big {
      svg + svg {
        margin-left: 8px;
      }
    }

    .Small {
      svg + svg {
        margin-left: 2px;
      }
    }
  }

  .ClearDetails {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (max-width: 478px) {
      margin-top: 120px;
    }

    @media (min-width: 479px) and (max-width: 991px) {
      margin-top: 180px;
    }

    @media (min-width: 992px) {
      margin-top: 112px;
    }

    .detailIntro {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .detailTitle1 {
        font-size: 23.52px;
        line-height: 40px;
        letter-spacing: -0.84px;
      }

      .detailTitle2 {
        font-size: 30.24px;
        line-height: 44px;
        letter-spacing: -1.08px;

        @media (min-width: 479px) and (max-width: 991px) {
          font-size: 40.24px;
        }

        @media (min-width: 992px) {
          width: 900px;
          font-size: 45px;
          line-height: 51px;
        }
      }

      .clearVideo {
        display: flex;
        width: 420px;
        justify-content: center;
        flex-shrink: 0;
        border-radius: 15px;

        @media (max-width: 478px) {
          width: 100%;
        }

        @media (min-width: 479px) and (max-width: 991px) {
          width: 100%;
        }

        @media (min-width: 992px) {
          width: 900px;
        }
      }
    }

    iframe {
      width: 100%;
      aspect-ratio: 16/9;
      border-radius: 15px;
    }

    .Line {
      width: 100%;
      height: 1px;
      border-top: 1px solid var(--gray);
      margin-top: 124.53px;
      margin-bottom: 40px;

      @media (min-width: 479px) and (max-width: 991px) {
        margin-top: 80.39px;
      }

      @media (min-width: 992px) {
        margin-top: 115.17px;
      }
    }

    .ClearTechInfo + .Line {
      margin-top: 130px;

      @media (min-width: 479px) and (max-width: 991px) {
        margin-top: 140px;
      }

      @media (min-width: 992px) {
        margin-top: 160px;
      }
    }

    .DownloadClear + .Line {
      margin-top: 109px;

      @media (min-width: 479px) and (max-width: 991px) {
        margin-top: 120px;
      }

      @media (min-width: 992px) {
        margin-top: 160px;
      }
    }

    .ClearGuide + .Line {
      margin-top: 142px;
    }

    .DetailParts {
      display: flex;
      flex-direction: column;
      gap: 55px;
      margin-top: 84px;

      @media (min-width: 479px) and (max-width: 991px) {
        gap: 55px;
        margin-top: 148px;
      }

      @media (min-width: 992px) {
        margin-top: 191.75px;
        display: flex;
        width: 900px;
        grid-template-columns: repeat(2, 1fr);
        align-items: flex-start;
        align-content: flex-start;
      }

      .DetailGrid {
        display: flex;
        flex-direction: column;
        gap: 63px;

        @media (min-width: 992px) {
          margin-top: 27px;
          display: grid;
          width: 900px;
          grid-auto-columns: 424px;
          grid-gap: 63px 52px;
          grid-template-columns: repeat(2, 1fr);
        }
      }

      .DetailTitle {
        width: 420px;

        @media (max-width: 478px) {
          width: 99%;
        }

        @media (min-width: 479px) and (max-width: 991px) {
          width: 100%;

          max-width: 700px;
        }

        @media (min-width: 992px) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
        }

        div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 22px;
        }

        h3 {
          font-size: 25.2px;
          line-height: 42px;
          letter-spacing: -0.9px;

          @media (min-width: 479px) {
            font-size: 30.24px;
            line-height: 44px;
            letter-spacing: -1.08px;
          }
        }

        p {
          font-size: 15.12px;
          line-height: 22px;
          letter-spacing: 0.06px;
          width: 418px;

          @media (max-width: 478px) {
            width: 100%;
          }

          @media (min-width: 479px) {
            font-size: 16.8px;
            line-height: 30px;
            letter-spacing: -0.6px;
            width: 100%;
          }

          @media (min-width: 992px) {
            width: 900px;
          }
        }

        .SuggestedContent {
          gap: 16px;

          @media (max-width: 478px) {
            width: 100%;
          }

          @media (min-width: 479px) and (max-width: 991px) {
            gap: 20px;
            width: 100%;

            max-width: 696.129px;
          }

          @media (min-width: 992px) {
            width: 100%;
          }

          h3 {
            font-size: 25.2px;
            line-height: 42px;
            letter-spacing: -0.9px;

            @media (min-width: 479px) {
              font-size: 30.24px;
              line-height: 44px;
              letter-spacing: -1.08px;
            }
          }

          p {
            @media (min-width: 479px) {
              width: 100%;
              font-size: 20.16px;
              line-height: 36px;
              letter-spacing: -0.72px;
            }
          }
        }
      }

      .DetailPart {
        display: flex;
        width: 420px;
        align-items: flex-start;
        align-content: flex-start;
        gap: 17px;
        flex-wrap: wrap;

        @media (max-width: 478px) {
          width: 100%;
        }

        @media (min-width: 479px) and (max-width: 991px) {
          width: 424px;
        }

        @media (min-width: 992px) {
          width: auto;
        }

        img {
          width: 421px;
          height: 211px;
          border-radius: 9.648px;
        }

        h4 {
          font-size: 20.16px;
          line-height: 36px;
          letter-spacing: -0.72px;
          margin-bottom: 3px;

          @media (min-width: 479px) {
            font-size: 23.52px;
            line-height: 40px;
            letter-spacing: -0.84px;
            margin-bottom: 13px;
          }

          @media (min-width: 992px) {
            margin-top: 0px;
          }
        }

        p {
          width: 100%;
          color: var(--lesserlightgray);
          font-size: 15.12px;
          line-height: 20px;
          letter-spacing: -0.46px;

          @media (min-width: 479px) and (max-width: 991px) {
            font-size: 16.8px;
            line-height: 30px;
            letter-spacing: -0.6px;
            width: 100%;
          }
        }

        .DAWIcon {
          margin-top: 16px;
          border-radius: 0;

          width: 210px;
          height: 44px;
          zoom: 90%;

          @media (min-width: 479px) {
            margin-top: 16px;
          }
        }
      }
    }

    .ClearTechInfo {
      gap: 0;
      display: flex;
      flex-direction: column;
      width: 100%;

      .DetailTitle {
        @media (min-width: 479px) and (max-width: 991px) {
          width: 100%;
          max-width: 620.205px;
        }

        @media (min-width: 992px) {
          width: 900px;
        }

        p {
          width: 100%;

          @media (min-width: 479px) {
            font-size: 16.8px;
            line-height: 30px;
            letter-spacing: -0.6px;
          }
        }
      }

      .DetailPart {
        display: grid;

        grid-auto-flow: row;
        grid-auto-columns: unset;
        grid-auto-rows: unset;

        @media (min-width: 479px) and (max-width: 991px) {
          width: 424px;
          gap: 0px;
        }

        @media (min-width: 992px) {
          display: grid;
          grid-auto-flow: column;
          grid-auto-columns: 424px;
          grid-gap: 51px;
          margin-top: 48px;
        }

        .TechInfoImage {
          width: 420px;
          height: 212px;
          margin-top: 50px;

          @media (max-width: 477px) {
            width: 100%;
          }

          @media (min-width: 479px) {
            width: 424px;
            height: 212px;
          }

          @media (min-width: 992px) {
            margin-top: 0;
          }
        }

        p {
          width: 100%;

          @media (min-width: 479px) {
            font-size: 16.8px;
            line-height: 30px;
            letter-spacing: -0.6px;
          }
        }

        h4 {
          @media (min-width: 479px) and (max-width: 991px) {
            margin-top: 17px;
          }
          @media (min-width: 992px) {
            margin-top: 0;
          }
        }
      }
    }

    .Line + .DetailParts {
      margin-top: 0;
    }

    .DownloadClear {
      display: flex;
      flex-direction: column;
      width: 100%;

      @media (min-width: 992px) {
        flex-direction: row;
        justify-content: space-between;
      }

      .DownloadText {
        margin-top: 54px;

        @media (min-width: 479px) {
          margin-top: 62px;
          width: 421px;
          justify-content: center;
        }

        h4 {
          font-size: 21.84px;
          line-height: 36px;
          letter-spacing: -0.78px;
        }
      }

      .DownloadButtons {
        display: flex;
        gap: 26px;
        margin-top: 40px;

        @media (max-width: 478px) {
          flex-direction: column;
        }

        @media (min-width: 479px) and (max-width: 991px) {
          margin-left: 0;
        }

        @media (min-width: 992px) {
          margin-left: 13px;
          margin-top: 62px;
          align-items: center;
        }
      }

      button {
        display: flex;
        width: 190px;
        height: 58px;
        min-width: 190px;
        padding: 19px 20px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        color: #fff;
        border-radius: 4.87px;
        background-color: var(--blue);

        svg {
          margin-left: 15px;
        }

        div {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .ClearGuide {
      width: 418.605px;

      @media (max-width: 478px) {
        width: 100%;
      }

      @media (min-width: 479px) and (max-width: 991px) {
        width: 100%;

        max-width: 697.674px;
      }

      @media (min-width: 992px) {
        width: 900px;
      }

      h4 {
        width: 100%;
        font-size: 20.16px;
        line-height: 36px;
        letter-spacing: -0.72px;

        @media (min-width: 479px) {
          font-size: 30.24px;
          line-height: 44px;
          letter-spacing: -1.08px;
        }
      }

      .GuideButton {
        margin-top: 17px;
        display: flex;
        justify-content: center;

        @media (min-width: 479px) and (max-width: 991px) {
          margin-top: 29px;
        }

        @media (min-width: 992px) {
          margin-top: 73px;
        }

        .GuideLink {
          border-radius: 4.87px;
          border: 1px solid #fff;
          background: rgba(32, 32, 32, 0);
          min-width: 175px;
          padding: 12px 15px;
          justify-content: center;
          align-items: center;
          color: #fff;
          text-align: center;
          font-size: 18.48px;
          line-height: 19.471px;
          display: flex;
          gap: 26px;

          @media (min-width: 479px) {
            gap: 10px;
          }

          svg {
            width: 17px;
            height: 17px;
          }
        }
      }
    }

    .PreviewImage {
      margin-top: 55px;
      text-align: center;
      zoom: 90%;

      @media (max-width: 478px) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      .MockupImage {
        width: 100%;
        border-radius: 15px;
      }
    }
  }
}

.DownloadPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 40px;

  @media (max-width: 991px) {
    padding: 0;
  }

  @media (max-width: 478px) {
    padding: 0;
  }

  .ProductTitle {
    margin-top: 40px;
    zoom: 65%;

    @media (min-width: 479px) {
      margin-top: 54.91px;
      zoom: 80%;
    }

    div + div {
      margin-top: 20px;
    }

    .Big {
      svg + svg {
        margin-left: 11px;
      }
    }

    .Small {
      > div {
        margin-left: 2px;
        font-size: 16.8.748px;
        line-height: 28.728px;
        letter-spacing: -0.622px;

        @media (min-width: 479px) {
          font-size: 26px;
          line-height: 36px;
          letter-spacing: -0.78px;
        }
      }
    }
  }

  > h1 {
    margin-top: 73px;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -1.08px;

    @media (max-width: 478px) {
      margin-top: 50px;
    }
  }

  > p {
    color: #fff;
    font-size: 17px;
    line-height: 30px;
    letter-spacing: -0.51px;
    margin-top: 50px;

    @media (min-width: 479px) {
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.72px;
      text-align: start;
    }
  }

  .DownloadNewsletter {
    margin-top: 120px;
    margin-bottom: 30px;
    width: 870px;

    @media (max-width: 991px) {
      width: 80%;
    }

    @media (max-width: 478px) {
      width: 100%;
    }

    h4 {
      display: none;
    }
  }
}

.ActivatePage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 40px;

  @media (max-width: 991px) {
    padding: 0;
  }

  @media (max-width: 478px) {
    padding: 0;
  }

  > h1 {
    margin-top: 64px;
    margin-bottom: 90px;
    font-family: 'Times New Roman';
    font-size: 3.125em;

    @media (max-width: 478px) {
      margin-top: 42px;
    }
  }

  section {
    text-align: left;
    width: 100%;
    margin-bottom: 40px;

    h2 {
      margin-top: 5px;
      margin-bottom: 4px;
      font-size: 1.25em;
      font-weight: 700;
      line-height: 166%;
      letter-spacing: 2px;
      text-transform: uppercase;
      padding-top: 3px;
      padding-bottom: 3px;

      @media (max-width: 478px) {
        font-size: 1.1em;
      }
    }

    ol {
      font-size: 1.25em;
      line-height: 200%;
      text-transform: uppercase;
      padding-left: 1.4em;
      letter-spacing: 2px;

      li {
        list-style-type: decimal;
      }
    }

    p {
      color: #fff;
      font-size: 1.25em;
      line-height: 166%;
      letter-spacing: 2px;
      text-transform: uppercase;

      @media (max-width: 478px) {
        font-size: 1.1em;
      }

      &.Note {
        color: #767676;
        line-height: 200%;
        margin-top: 25px;
      }
    }
  }

  hr {
    width: 100%;
    height: 1px;
    border-top: 1px solid #444;
    margin-top: 20px;
    margin-bottom: 52px;
  }

  form {
    display: flex;

    input[type='text'] {
      width: 100%;
      height: 60px;
      border: 1px solid #fff;
      border-radius: 4px;
      padding: 0 20px;
      font-size: 1.25em;
      line-height: 166%;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: #fff;
      margin-bottom: 20px;
    }

    input[type='submit'] {
      height: 60px;
      border: 1px solid var(--blue);
      border-radius: 4px;
      background-color: var(--blue);
      color: #fff;
      font-size: 1.45em;
      line-height: 166%;
      cursor: pointer;
      padding-left: 90px;
      padding-right: 90px;

      &[disabled] {
        background-color: var(--slightlylightergray);
        border-color: var(--slightlylightergray);
        color: #fff;
        cursor: default;
      }
    }
  }

  a[type='button'] {
    display: inline-block;
    text-align: center;
    padding: 18px 32px;
    background-color: var(--blue);
    color: #fff;
    margin-top: 8px;
    width: 100%;
    border-radius: 6.5px;
    font-size: 1.45em;
  }

  .ErrorTitle {
    font-family: Inter;
    font-size: 3em;
    font-weight: 700;
    color: var(--red) !important;
    margin-bottom: 24px;
  }

  .ErrorContent {
    font-size: 1.25em;
    color: var(--red) !important;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
}

.Success {
  color: var(--blue) !important;
}

.Error {
  color: var(--red) !important;
}

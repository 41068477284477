* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: 400;
  list-style: none;
  font-style: normal;
  word-break: keep-all;
  text-decoration: none;
  box-sizing: border-box;
  vertical-align: baseline;
  background-color: transparent;
  font-family: 'Pretendard', sans-serif;
}

html {
  --blue: #0061ff;
  --violetblue: #3636e4;
  --green: #31b743;
  --red: #ff5a00;
  --lightgray: #afafaf;
  --lesserlightgray: #a9a9a9;
  --lesslightgray: #a5a5a5;
  --lightergray: #909090;
  --slightlylightergray: #767676;
  --gray: #696969;
  --darkergray: #5d5d5d;
  --darkgray: #292929;
  --moredarkergray: #161616;
  --red: #ff0000;
}

.LegalContainer {
  padding: 0;

  h1 {
    font-family: 'Times New Roman' !important;
    font-size: 2.875em;
    color: #fff;
    line-height: 163%;
    margin-top: 150px;
    margin-bottom: 38px;
  }

  h2 {
    font-size: 1.875em;
    color: #fff;
    line-height: 133%;
    margin-top: 50px;
    margin-bottom: 37px;
  }

  h3 {
    font-size: 1.25em;
    font-weight: 700;
    color: #fff;
    line-height: 150%;
    margin-top: 30px;
    margin-bottom: 24px;
  }

  h4 {
    font-size: 1.1em;
    font-weight: 700;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  h5 {
    font-size: 1em;
    font-weight: 700;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .Date {
    color: var(--red);
    font-family: Inter;
    font-size: 1.5em;
    margin-bottom: 70px;
  }

  p {
    color: #fff;
    font-size: 1.125em;
    margin-top: 30px;
    margin-bottom: 30px;
    overflow-wrap: break-word;

    > ul {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  ul {
    color: #fff;

    li {
      list-style: disc;
      margin-left: 40px;
      font-size: 1em;
    }
  }

  ol {
    li {
      list-style: decimal;
      margin-left: 40px;
    }
  }

  .Line {
    width: 100%;
    height: 1px;
    background-color: var(--lesslightgray);
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .Required {
    color: var(--blue);
  }

  small {
    color: var(--gray);
    font-size: 1em;

    a {
      color: var(--gray);
      text-decoration: underline;
    }
  }

  .CheckboxContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 33px;

    .Checkbox {
      width: 28px;
      height: 28px;
      vertical-align: middle;

      + label {
        margin-left: 20px;
        font-size: 1.125em;
        color: #fff;
        vertical-align: middle;
        line-height: 150%;
      }
    }
  }

  section {
    margin-bottom: 33px;
  }

  button {
    font-size: 1.375em;
    line-height: 88%;
    color: #fff;
    min-width: 200px;
    padding: 19px 20px 19px 32px;
    border: 1px solid #fff;
    border-radius: 4px;
    white-space: nowrap;

    @media (max-width: 478px) {
      font-size: 1em;
      min-width: 140px;
      padding: 19px 20px;
    }

    &.Primary {
      background-color: var(--blue);
      border-color: var(--blue);

      &:disabled {
        background-color: var(--slightlylightergray);
        border-color: var(--slightlylightergray);

        &:hover {
          cursor: default;
        }
      }
    }

    + button {
      margin-left: 26px;
    }
  }

  table {
    border: 1px solid var(--darkergray);
    border-radius: 4px;
    border-spacing: 0;

    & + table {
      margin-top: 30px;
    }

    tr {
      th {
        font-size: 0.75em;
        font-weight: 600;
        line-height: 130%;
        padding: 10px 12px;
        border-left: 1px solid var(--darkergray);
        background-color: var(--moredarkergray);
        vertical-align: middle;

        &:first-of-type {
          border-top-left-radius: 4px;
        }

        &:last-of-type {
          border-top-right-radius: 4px;
        }
      }

      td {
        font-size: 0.75em;
        font-weight: 400;
        line-height: 130%;
        padding: 10px 12px;
        vertical-align: middle;
        border-top: 1px solid var(--darkergray);
        border-left: 1px solid var(--darkergray);
      }
    }
  }

  a {
    color: #fff;
    text-decoration: underline;
  }

  strong {
    font-weight: 700;
  }
}

.CookieConsentPopup {
  position: fixed;
  bottom: 0;
  right: 73px;
  width: 600px;
  padding: 20px 40px;
  background-color: var(--blue);

  @media (max-width: 478px) {
    width: 100%;
    max-width: 100%;
    left: 0;
    padding: 25px 30px;
  }

  p {
    color: #fff;
    font-size: 0.875em;
    padding-top: 8px;
    padding-bottom: 8px;
    display: table-cell;
    vertical-align: middle;

    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 17px;

    @media (max-width: 478px) {
      margin-bottom: 0;
      gap: 8px;
    }

    a {
      flex: 1;
      color: #fff;
      font-family: Inter;
      font-size: 1.3em;
      white-space: nowrap;

      @media (max-width: 478px) {
        font-size: 1em;
      }
    }

    button {
      flex: 1;
      color: #fff;
      padding: 0 20px;
      height: 58px;
      line-height: 58px;
      background-color: transparent;
      border: 1px solid #fff;
      border-radius: 4px;
      font-size: 1.375em;
      white-space: nowrap;

      @media (max-width: 478px) {
        font-size: 1em;
        height: 40px;
        line-height: 40px;
        padding: 0 12px;
      }

      &:hover {
        cursor: pointer;
      }

      &.Primary {
        background-color: #fff;
        color: var(--blue);
      }

      &.NoOutline {
        border: 1px solid transparent;
      }
    }
  }
}

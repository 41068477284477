.ProductPage {
  height: calc(687px * 2 - 92px);

  @media (min-width: 478px) and (max-width: 991px) {
    height: calc(950px * 2 - 92px);
  }

  @media (min-width: 991px) {
    height: calc(734px * 2 - 92px);
  }

  .ProductContents {
    position: relative;
    width: 100%;
    height: 100%;

    @media (min-width: 991px) {
      max-width: 900px;
    }

    .ProductSubtitle {
      position: absolute;
      bottom: 151px;
      left: 38px;
      right: 38px;
      font-family: Inter;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: -0.9px;

      @media (min-width: 479px) and (max-width: 991px) {
        bottom: 192px;
        left: 67px;
        right: 67px;
        font-size: 40px;
      }

      @media (min-width: 991px) {
        bottom: 171px;
        font-size: 40px;
      }
    }

    .ProductActions {
      position: absolute;
      bottom: 58px;
      left: 38px;

      @media (min-width: 479px) and (max-width: 991px) {
        bottom: 92px;
        left: 67px;
      }

      @media (min-width: 991px) {
        bottom: 80px;
      }

      button {
        color: #fff;
        border-radius: 4px;
        padding: 16px 17px 16px 26px;
        white-space: nowrap;
        font-size: 18.5px;
        min-width: 185px;
        border: 1px solid transparent;
        background-color: var(--blue);
      }
    }
  }

  .ProductShift {
    position: absolute;
    left: 0;
    right: 0;
    top: var(--banner-height);
    height: 687px;
    overflow: hidden;
    display: flex;
    justify-content: center;

    @media (min-width: 479px) and (max-width: 991px) {
      width: 100vw;
      height: 950px;
      margin-left: 0;
      margin-right: 0;
    }

    @media (min-width: 991px) {
      height: 734px;
    }

    .ProductTitle {
      position: absolute;
      top: 110px;
      left: 38px;

      @media (min-width: 479px) and (max-width: 991px) {
        top: 150px;
        left: 67px;
      }

      @media (min-width: 991px) {
        top: 130px;
      }

      .ShiftLogo {
        zoom: 0.83;

        @media (min-width: 479px) and (max-width: 991px) {
          zoom: 1.3;
        }

        @media (min-width: 991px) {
          zoom: 1.2;
        }
      }

      h2 {
        margin-top: 7px;
        display: flex;
        gap: 9px;
        zoom: 0.7;

        @media (min-width: 479px) and (max-width: 991px) {
          zoom: 1.1;
        }

        @media (min-width: 991px) {
          zoom: 1;
        }
      }

      .ProductDescription {
        margin-top: 2px;
        margin-bottom: 6px;

        > svg {
          zoom: 0.81;

          @media (min-width: 479px) and (max-width: 991px) {
            zoom: 1.25;
            margin-top: 5px;
            margin-bottom: 9px;
          }

          @media (min-width: 991px) {
            zoom: 1.15;
            margin-top: 5px;
            margin-bottom: 9px;
          }
        }
      }

      .OpenBeta {
        zoom: 0.6;

        @media (min-width: 479px) and (max-width: 991px) {
          zoom: 0.95;
        }

        @media (min-width: 991px) {
          zoom: 0.9;
        }
      }
    }

    .ProductBg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 687px;
      z-index: -2;
      background-image: url(../common/assets/images/v4/product/shift/Shift_KeyImg.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;

      @media (min-width: 479px) and (max-width: 991px) {
        height: 950px;
      }

      @media (min-width: 991px) {
        height: 734px;
      }
    }

    .ProductUi {
      position: absolute;
      bottom: 39px;
      right: 13px;
      width: 147px;
      height: 307px;
      z-index: -1;
      background-image: url(../common/assets/images/v4/product/shift/Shift_UI.png);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;

      @media (min-width: 479px) and (max-width: 991px) {
        bottom: 45px;
        right: 40px;
        width: 230px;
        height: 471px;
      }

      @media (min-width: 991px) {
        bottom: 87px;
        right: 117px;
        width: 230px;
        height: 471px;
      }
    }
  }

  .ProductClear {
    position: absolute;
    top: calc(var(--banner-height) + 687px);
    left: 0;
    right: 0;
    height: 687px;
    overflow: hidden;
    display: flex;
    justify-content: center;

    @media (min-width: 479px) and (max-width: 991px) {
      top: calc(var(--banner-height) + 950px);
      height: 950px;
    }

    @media (min-width: 991px) {
      top: calc(var(--banner-height) + 734px);
      height: 734px;
    }

    .ProductTitle {
      position: absolute;
      top: 50px;
      left: 38px;

      @media (min-width: 479px) and (max-width: 991px) {
        top: 88px;
        left: 67px;
      }

      @media (min-width: 991px) {
        top: 103px;
      }

      h2 {
        display: flex;
        gap: 9px;
        zoom: 0.55;
        margin-top: 0;

        @media (min-width: 479px) and (max-width: 991px) {
          zoom: 0.75;
        }

        @media (min-width: 991px) {
          zoom: 0.75;
        }
      }

      .ProductDescription {
        font-size: 17px;
        margin-top: 14px;
        margin-bottom: 10px;
        line-height: 25px;

        @media (min-width: 479px) and (max-width: 991px) {
          font-size: 22px;
          margin-top: 20px;
          margin-bottom: 15px;
        }

        @media (min-width: 991px) {
          font-size: 22px;
          margin-top: 20px;
          margin-bottom: 15px;
        }
      }
    }

    .ProductBg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 687px;
      z-index: -2;
      background-image: url(../common/assets/images/v4/product/clear/Clear_KeyImg.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;

      @media (min-width: 479px) and (max-width: 991px) {
        height: 950px;
      }

      @media (min-width: 991px) {
        height: 734px;
      }
    }

    .ProductUi {
      position: absolute;
      bottom: 47px;
      right: -68px;
      width: 332px;
      height: 218px;
      z-index: -1;
      background-image: url(../common/assets/images/v4/product/clear/Clear_UI.png);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;

      @media (min-width: 479px) and (max-width: 991px) {
        bottom: 56px;
        right: -83px;
        width: 569px;
        height: 377px;
      }

      @media (min-width: 991px) {
        bottom: 137px;
        right: -87px;
        width: 569px;
        height: 377px;
      }
    }
  }
}

.PaymentPage {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 478px) {
    padding: 0 30px;
  }

  > div {
    width: 500px;
    margin-top: 170px;
    margin-bottom: 170px;

    @media (max-width: 478px) {
      width: 100%;
      margin-top: 100px;
      margin-bottom: 100px;
    }

    h1 {
      font-size: 2.1em;
      color: #fff;
      letter-spacing: 2px;
      text-transform: uppercase;
      line-height: 117%;
    }

    p {
      color: var(--lightgray);

      font-size: 1em;
      line-height: 130%;
      letter-spacing: 0.779px;
      text-transform: uppercase;
      margin-top: 70px;

      @media (max-width: 478px) {
        margin-top: 30px;
      }
    }
  }

  .Warning {
    color: var(--red);
  }
}
